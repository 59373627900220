import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    adminGetEntryListAPI,
    bestEntryAPI,
    changeEntryCategoryAPI,
    changeScoreStatusAPI,
    createEntryAPI,
    deleteEntryAPI,
    getEntryAPI,
    getEntryListAPI,
    scoreEntryAPI,
    submitEntryAPI,
    submitEntryAdminAPI,
    updateEntryAPI
} from 'APIs/entry';
import { adminGetFileURLAPI, getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';

// ==============================|| REDUX STORE: ENTRY DATA ||============================== //

const GET_ENTRY_LIST = createAsyncThunk('entry/list', async () => {
    console.log('calling GET_ENTRY_LIST');
    const response = await getEntryListAPI();
    return response;
});

const ADMIN_GET_ENTRY_LIST = createAsyncThunk('admin/entry/list', async () => {
    console.log('calling GET_ADMIN_ENTRY_LIST');
    const response = await adminGetEntryListAPI();
    return response;
});

// Upload files - not all files for Entries are private
const uploadFile = async ({ file, name, orgID }) => {
    if (!(file instanceof File)) {
        throw new Error(`Invalid file: ${name}`);
    }
    // Get s3 upload URL
    const nameExtension = file.name.split('.').pop();
    let cleanName = file.name.replace(`.${nameExtension}`, '');
    cleanName = cleanName.replace(/[^a-zA-Z0-9]/g, '');
    cleanName = `${cleanName}.${nameExtension}`;
    const fileName = `${name}-${cleanName}`;
    const path = `entries`;
    const mimeType = file.type;
    let urlResponse;
    // Check if this is an admin calling for the update
    if (orgID) {
        urlResponse = await adminGetFileURLAPI({ fileName, path, mimeType, orgID, isPrivate: true });
    } else {
        urlResponse = await getFileURLAPI({ fileName, path, mimeType, isPrivate: true });
    }
    // Upload to s3
    const { url } = urlResponse;
    await uploadFileWithURLAPI({ url, file });
    return url.split('?')[0];
};

// Upload any files
const checkForFiles = async ({ data, orgID }) => {
    const { supportingDoc, supportingImage, logoLight, logoDark } = data;
    if (supportingDoc) {
        const url = await uploadFile({ file: supportingDoc, name: 'supportingDoc', orgID });
        delete data.supportingDoc;
        data.supportingDocURL = url;
    }
    if (supportingImage) {
        const url = await uploadFile({ file: supportingImage, name: 'supportingImage', orgID });
        delete data.supportingImage;
        data.supportingImageURL = url;
    }
    if (logoLight) {
        const url = await uploadFile({ file: logoLight, name: 'logoLight', orgID });
        delete data.logoLight;
        data.logoLightURL = url;
    }
    if (logoDark) {
        const url = await uploadFile({ file: logoDark, name: 'logoDark', orgID });
        delete data.logoDark;
        data.logoDarkURL = url;
    }
    return data;
};

const UPDATE_ENTRY = createAsyncThunk('entry/update', async ({ id, awardID, data, orgID }) => {
    const modifiedData = await checkForFiles({ data, orgID });
    const response = await updateEntryAPI({ id, awardID, data: modifiedData });
    return response;
});

const CHANGE_ENTRY_CATEGORY = createAsyncThunk('entry/category', async ({ id, awardID, categoryID }) => {
    const response = await changeEntryCategoryAPI({ id, awardID, categoryID });
    return response;
});

const DELETE_ENTRY = createAsyncThunk('entry/delete', async ({ id, awardID }) => {
    const response = await deleteEntryAPI({ id, awardID });
    return response;
});

const GET_ENTRY = createAsyncThunk('entry/get', async ({ id, awardID }) => {
    const response = await getEntryAPI({ id, awardID });
    return response;
});

const CREATE_ENTRY = createAsyncThunk('entry/create', async ({ data, isSubmit }) => {
    // Upload any files
    const modifiedData = await checkForFiles({ data });
    const response = await createEntryAPI({ data: modifiedData, isSubmit });
    return response;
});

const SCORE_ENTRY = createAsyncThunk('entry/score', async ({ id, awardID, categoryID, score, submit = false }) => {
    const response = await scoreEntryAPI({ id, awardID, categoryID, data: score, submit });
    return response;
});

const CHANGE_SCORE_STATUS = createAsyncThunk('entry/score/status', async ({ id, awardID, status }) => {
    const response = await changeScoreStatusAPI({ id, awardID, status });
    return response;
});

const BEST_ENTRY = createAsyncThunk('entry/best', async ({ bestData }) => {
    const { entryID, awardID, bestEntry } = bestData;
    const response = await bestEntryAPI({ id: entryID, awardID, data: bestEntry });
    return response;
});

const SUBMIT_ENTRY = createAsyncThunk('entry/submit', async ({ id, data }) => {
    // Upload any files
    const modifiedData = await checkForFiles({ data });
    const response = await submitEntryAPI({ id, data: modifiedData });
    return response;
});

const SUBMIT_ENTRY_ADMIN = createAsyncThunk('entry/submit/admin', async ({ id, data }) => {
    const response = await submitEntryAdminAPI({ id, data });
    return response;
});

const initialState = {
    initialized: false,
    entries: []
};

const entrySlice = createSlice({
    name: 'entry',
    initialState,
    reducers: {
        create(state, action) {
            state.entries.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.entries = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_ENTRY.fulfilled, (state, action) => {
                state.entries.push(action.payload);
            })
            .addCase(GET_ENTRY.fulfilled, (state, action) => {
                state.entries = state.entries.map((entry) => {
                    if (entry.id === action.payload.id && entry.awardID === action.payload.awardID) {
                        return { ...entry, ...action.payload };
                    }
                    return entry;
                });
            })
            .addCase(UPDATE_ENTRY.fulfilled, (state, action) => {
                state.entries = state.entries.map((entry) => {
                    if (entry.id === action.payload.id && entry.awardID === action.payload.awardID) {
                        return { ...entry, ...action.payload };
                    }
                    return entry;
                });
            })
            .addCase(CHANGE_ENTRY_CATEGORY.fulfilled, (state, action) => {
                const updatedEntry = action.payload.entry;
                state.entries = state.entries.map((entry) => {
                    if (entry.id === updatedEntry.id && entry.awardID === updatedEntry.awardID) {
                        return { ...entry, ...updatedEntry };
                    }
                    return entry;
                });
            })
            .addCase(SUBMIT_ENTRY.fulfilled, (state, action) => {
                state.entries = state.entries.map((entry) => {
                    if (entry.id === action.payload.id && entry.awardID === action.payload.awardID) {
                        return { ...entry, ...action.payload };
                    }
                    return entry;
                });
            })
            .addCase(SUBMIT_ENTRY_ADMIN.fulfilled, (state, action) => {
                state.entries = state.entries.map((entry) => {
                    if (entry.id === action.payload.id && entry.awardID === action.payload.awardID) {
                        return { ...entry, ...action.payload };
                    }
                    return entry;
                });
            })
            .addCase(CHANGE_SCORE_STATUS.fulfilled, (state, action) => {
                state.entries = state.entries.map((entry) => {
                    if (entry.id === action.payload.id && entry.awardID === action.payload.awardID) {
                        return { ...entry, ...action.payload };
                    }
                    return entry;
                });
            })
            .addCase(SCORE_ENTRY.fulfilled, (state, action) => {
                console.log('Scoring Entry');
                state.entries = state.entries.map((entry) => {
                    if (entry.id === action.payload.id && entry.awardID === action.payload.awardID) {
                        console.log(`Updating entry: ${entry.id}`);
                        return { ...entry, ...action.payload };
                    }
                    return entry;
                });
            })
            .addCase(BEST_ENTRY.fulfilled, (state, action) => {
                state.entries = state.entries.map((entry) => {
                    // check exists in payload
                    const updatedEntry = action.payload.find((item) => item.id === entry.id && item.awardID === entry.awardID);
                    if (updatedEntry) {
                        return updatedEntry;
                    }
                    return entry;
                });
            })
            .addCase(DELETE_ENTRY.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                const deletedAwardID = action.meta.arg.awardID;
                if (deletedID && deletedAwardID) {
                    state.entries = state.entries.filter((item) => item.awardID !== deletedAwardID || item.id !== deletedID);
                }
            })
            .addCase(GET_ENTRY_LIST.fulfilled, (state, action) => {
                state.entries = [...action.payload];
                state.initialized = true;
            })
            .addCase(ADMIN_GET_ENTRY_LIST.fulfilled, (state, action) => {
                state.entries = [...action.payload];
                state.initialized = true;
            });
    }
});

const { create, logout } = entrySlice.actions;
const initialized = (state) => state?.entry?.initialized;
const entries = (state) => state?.entry?.entries || [];

export {
    ADMIN_GET_ENTRY_LIST,
    BEST_ENTRY,
    CHANGE_ENTRY_CATEGORY,
    CHANGE_SCORE_STATUS,
    CREATE_ENTRY,
    DELETE_ENTRY,
    GET_ENTRY,
    GET_ENTRY_LIST,
    SCORE_ENTRY,
    SUBMIT_ENTRY,
    SUBMIT_ENTRY_ADMIN,
    UPDATE_ENTRY,
    create,
    entries,
    initialized,
    logout
};
export default entrySlice.reducer;
