import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createAwardAPI, deleteAwardAPI, deleteAwardCategoryAPI, getAwardListAPI, getAwardSummaryAPI, updateAwardAPI } from 'APIs/award';
import { createZipFileAPI } from 'APIs/file';

// ==============================|| REDUX STORE: AWARD DATA ||============================== //

const GET_AWARD_LIST = createAsyncThunk('award/list', async () => {
    console.log('calling GET_AWARD_LIST');
    const response = await getAwardListAPI();
    return response;
});

const GET_AWARD_SUMMARY = createAsyncThunk('award/summary', async ({ id }) => {
    console.log('calling GET_AWARD_SUMMARY');
    const response = await getAwardSummaryAPI({ id });
    return response;
});

const UPDATE_AWARD = createAsyncThunk('award/update', async ({ id, data }) => {
    const response = await updateAwardAPI({ id, data });
    return response;
});

const CREATE_AWARD = createAsyncThunk('award/create', async ({ data }) => {
    const response = await createAwardAPI({ data });
    return response;
});

const DELETE_AWARD = createAsyncThunk('award/delete', async ({ id }) => {
    const response = await deleteAwardAPI({ id });
    return response;
});

const DELETE_AWARD_CATEGORY = createAsyncThunk('award/category/delete', async ({ awardID, categoryID }) => {
    const response = await deleteAwardCategoryAPI({ awardID, categoryID });
    return response;
});

const CREATE_ZIP_FILE = createAsyncThunk('award/zip', async ({ awardID, fileType }) => {
    console.log('calling CREATE_ZIP_FILE');
    const response = await createZipFileAPI({ awardID, fileType });
    return response;
});

const initialState = {
    initialized: false,
    defaultAwardID: '',
    awards: [],
    awardSummary: {}
};

const awardSlice = createSlice({
    name: 'award',
    initialState,
    reducers: {
        create(state, action) {
            state.awards.push(action.payload.data);
        },
        SET_DEFAULT_AWARD(state, action) {
            if (action.payload.defaultAwardID) {
                state.defaultAwardID = action.payload.defaultAwardID;
            }
        },
        logout(state) {
            state.initialized = false;
            state.defaultAwardID = '';
            state.awards = [];
            state.awardSummary = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_AWARD.fulfilled, (state, action) => {
                state.awards.push(action.payload);
            })
            .addCase(UPDATE_AWARD.fulfilled, (state, action) => {
                state.awards = state.awards.map((award) => {
                    if (award.id === action.payload.id) {
                        return { ...award, ...action.payload };
                    }
                    return award;
                });
            })
            .addCase(CREATE_ZIP_FILE.fulfilled, (state, action) => {
                console.log('CREATE_ZIP_FILE Fulfilled', action.payload);
                state.awards = state.awards.map((award) => {
                    if (award.id === action.payload.id) {
                        console.log('Updating award');
                        return { ...award, ...action.payload };
                    }
                    return award;
                });
            })
            .addCase(DELETE_AWARD_CATEGORY.fulfilled, (state, action) => {
                state.awards = state.awards.map((award) => {
                    if (award.id === action.payload.id) {
                        return { ...award, ...action.payload };
                    }
                    return award;
                });
            })
            .addCase(DELETE_AWARD.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.awards = state.awards.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(GET_AWARD_LIST.fulfilled, (state, action) => {
                state.awards = [...action.payload];
                state.initialized = true;
            })
            .addCase(GET_AWARD_SUMMARY.fulfilled, (state, action) => {
                state.awardSummary = { ...action.payload };
            })
            .addMatcher(
                (action) => action.type.includes('account/login/fulfilled'),
                (state, action) => {
                    const defaultAwardID = action.payload.user?.defaultAwardID;
                    if (defaultAwardID) {
                        state.defaultAwardID = defaultAwardID;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.includes('user/update/admin/fulfilled'),
                (state, action) => {
                    if (action.payload?.awards) {
                        const awardsUpdated = action.payload.awards.map((item) => item.id);
                        console.log('awardsUpdated', awardsUpdated);
                        state.awards = [...state.awards.filter((t) => !awardsUpdated.includes(t.id)), ...action.payload.awards];
                    }
                }
            );
    }
});

const { create, logout, SET_DEFAULT_AWARD } = awardSlice.actions;
const initialized = (state) => state?.award?.initialized;
const awards = (state) => state?.award?.awards;

export {
    awards,
    create,
    CREATE_AWARD,
    CREATE_ZIP_FILE,
    DELETE_AWARD,
    DELETE_AWARD_CATEGORY,
    GET_AWARD_LIST,
    GET_AWARD_SUMMARY,
    initialized,
    logout,
    SET_DEFAULT_AWARD,
    UPDATE_AWARD
};
export default awardSlice.reducer;
