import { apiFileUpload, apiGet } from './apiClient';

// Org's bucket directory will be determined from users OrdID
const getFileURLAPI = async ({ fileName, path, mimeType, fileRef, isPrivate = true }) => {
    const encodedMIME = encodeURIComponent(mimeType);
    const url = `/file/geturl?name=${fileName}&path=${path}&mimeType=${encodedMIME}&fileRef=${fileRef}&isPrivate=${isPrivate}`;
    const response = await apiGet({ url });
    return response;
};

// Needed as Admins need to post to any Org's bucket directory
const adminGetFileURLAPI = async ({ fileName, path, mimeType, fileRef, orgID, isPrivate }) => {
    const encodedMIME = encodeURIComponent(mimeType);
    const url = `/admin/file/geturl?name=${fileName}&path=${path}&mimeType=${encodedMIME}&fileRef=${fileRef}&orgID=${orgID}&isPrivate=${isPrivate}`;
    const response = await apiGet({ url });
    return response;
};

const getFileDownloadURLAPI = async (location) => {
    const url = `/file/geturl/download?url=${location}`;
    const response = await apiGet({ url });
    return response;
};

const uploadFileWithURLAPI = async ({ url, file }) => {
    const uploadResponse = await apiFileUpload({ url, file });
    console.log('Upload File:', uploadResponse);
};

const createZipFileAPI = async ({ awardID, fileType }) => {
    const url = `/file/zip?id=${awardID}&type=${fileType}`;
    const response = await apiGet({ url });
    return response;
};

export { adminGetFileURLAPI, createZipFileAPI, getFileDownloadURLAPI, getFileURLAPI, uploadFileWithURLAPI };
