import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';

// Pages
const AdminDashboard = Loadable(lazy(() => import('views/dashboard/admin')));
const AwardsPage = Loadable(lazy(() => import('views/awards')));
const AwardDetail = Loadable(lazy(() => import('views/awards/AwardDetail')));
const EntriesPage = Loadable(lazy(() => import('views/entries')));
const EntryDetail = Loadable(lazy(() => import('views/entry')));
const ScoresList = Loadable(lazy(() => import('views/scores')));
const ScoreDetails = Loadable(lazy(() => import('views/scores/scoreDetails')));
const InvoicesPage = Loadable(lazy(() => import('views/invoices')));
const UsersPage = Loadable(lazy(() => import('views/users')));
const JudgesPage = Loadable(lazy(() => import('views/judges')));
const CategoriesPage = Loadable(lazy(() => import('views/categories')));
const CategoryDetailPage = Loadable(lazy(() => import('views/categories/categoryDetail')));
const UserDetail = Loadable(lazy(() => import('views/users/UserDetail')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const PricesPage = Loadable(lazy(() => import('views/prices')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));
const UtilsPage = Loadable(lazy(() => import('views/utils')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: '/',
            element: <AdminDashboard />
        },
        {
            path: '/awards',
            element: <AwardsPage />
        },
        {
            path: '/award',
            element: <AwardDetail />,
            children: [{ path: ':id', element: <AwardDetail /> }]
        },
        {
            path: '/categories',
            element: <CategoriesPage />
        },
        {
            path: '/category',
            element: <CategoryDetailPage />
        },
        {
            path: '/judges',
            element: <JudgesPage />
        },
        {
            path: '/scores',
            element: <ScoresList />
        },
        {
            path: '/scores/entry',
            element: <ScoreDetails />
        },
        {
            path: '/entries',
            element: <EntriesPage />
        },
        {
            path: '/entry',
            element: <EntryDetail />
        },
        {
            path: '/invoices',
            element: <InvoicesPage />
        },
        {
            path: '/prices',
            element: <PricesPage />
        },
        {
            path: '/users',
            element: <UsersPage />
        },
        {
            path: '/utils',
            element: <UtilsPage />
        },
        {
            path: '/user',
            element: <UserDetail />,
            children: [{ path: ':id', element: <UserDetail /> }]
        },
        {
            path: '/page2',
            element: <PlayPage />
        },
        {
            path: 'admin/settings',
            element: <UserSettings />
        }
    ]
};

export default MainRoutes;
