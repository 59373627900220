import { apiGet } from './apiClient';

const getJudgeAPI = async (id) => {
    const response = await apiGet(`/judge?id=${id}`);
    return response;
};

const getJudgeListAPI = async ({ awardID }) => {
    const response = await apiGet({ url: `/judge/list?awardID=${awardID}` });
    return response;
};

export { getJudgeAPI, getJudgeListAPI };
